import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  DropdownDataType,
  IDropdownItem,
} from '@/components/DropdownDataSelect/types';
import request from '@/utils/request';

export const DROPDOWN_API = '/api/v1/dropdowns';

export const options = createQueryKeys('options', {
  type: (type: DropdownDataType) => ({
    queryKey: [type],
    queryFn: (): Promise<IDropdownItem[]> => {
      const url = `${DROPDOWN_API}/${type}` as const;
      return request.get(url).then((res) => res.data);
    },
  }),
});
