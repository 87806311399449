import type { TourProps } from 'antd';

import { getTargetElement } from '../utils';
import { WelcomeIntro } from './WelcomeIntro';

export const NEW_REQUIREMENT_STEPS: TourProps['steps'] = [
  {
    title: 'Guide',
    description: <WelcomeIntro />,
  },
  {
    title: 'Fill in your request',
    description:
      'Describe your requirement in a few sentences and pass to your BD directly.',
    target: getTargetElement('requirement-description'),
    placement: 'bottom',
  },
  {
    title: 'Pass to your BD',
    description: 'Your BD will follow up your requirement from here.',
    target: getTargetElement('requirement-pass-bd'),
    placement: 'topRight',
  },
  {
    title: '(Optional) Specify your requirement',
    description:
      'You can also provide additional information for the requirement.',
    target: getTargetElement('requirement-steps'),
    placement: 'right',
  },
];
