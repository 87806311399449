import { Icon } from '@umijs/max';
import { TourProps } from 'antd';

import { getTargetElement } from '../utils';

export const MESSAGES_STEPS: TourProps['steps'] = [
  {
    title: 'Guide',
    description: (
      <div className="mt-4 w-[440px] px-6 py-4">
        <div className="flex flex-col items-center justify-center gap-4">
          <Icon
            icon="local:collaboration"
            width="48"
            height="48"
            className="text-[#9168ff]"
          />
          <div className="text-center">
            <h3>Messages</h3>
            <p>All your messages go here</p>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: 'Action needed',
    description: 'Messages here require your action to go next step.',
    placement: 'right',
    target: getTargetElement('messages-Action Needed'),
  },
  {
    title: 'Progress updates',
    description:
      'Any project progress updates we will send you a message, such as requirement updates, new reports and etc.',
    placement: 'right',
    target: getTargetElement('messages-Progress Updates'),
  },
  {
    title: 'Historical messages',
    description: 'All read messages will go here.',
    placement: 'right',
    target: getTargetElement('messages-Historical Messages'),
  },
  {
    title: 'WBP Code',
    description: 'Filter your messages by WBP code.',
    placement: 'right',
    target: getTargetElement('messages-wbp-code'),
  },
];
