import { TourProps } from 'antd';

import { getTargetElement } from './utils';

export const TOUR_ENDING_STEPS: TourProps['steps'] = [
  {
    title: 'Thank you for going so far!',
    description:
      'One last tip before you start, you can always revisit the guide tour by clicking here.',
    target: () => getTargetElement('show-tour'),
  },
];
