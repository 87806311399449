import { MenuFoldOutlined } from '@ant-design/icons';
import { Icon } from '@umijs/max';
import { Button, Tooltip } from 'antd';

import { SITE_EMAIL } from '@/constants';

const MenuFooter = (props: any) => {
  const { collapsed, setInitialState } = props;

  const toggleMenu = () => {
    setInitialState((preInitialState: any) => {
      return {
        ...preInitialState,
        settings: {
          ...preInitialState.settings,
          collapsed: !collapsed,
        },
      };
    });
  };
  if (collapsed) {
    return (
      <div className="flex flex-col gap-4 justify-center items-center">
        <Tooltip
          title={`Email us for any questions: ${SITE_EMAIL}`}
          placement="right"
        >
          <Button
            ghost
            className="!border-none"
            icon={<Icon icon="local:contact-us" />}
            href={`mailto:${SITE_EMAIL}`}
          ></Button>
        </Tooltip>
        <Button
          className="!border-none"
          ghost
          onClick={toggleMenu}
          icon={<Icon icon="local:menu-unfold" />}
        ></Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 justify-center items-end text-white px-2">
      <Button
        size="small"
        className="!border-none"
        ghost
        icon={<Icon icon="local:contact-us" />}
        href={`mailto:${SITE_EMAIL}`}
      >
        <span className="text-[12px]">{SITE_EMAIL}</span>
      </Button>
      <Button
        className="!border-none"
        ghost
        onClick={toggleMenu}
        icon={<MenuFoldOutlined />}
      ></Button>
    </div>
  );
};

export default MenuFooter;
