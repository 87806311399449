import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getFeatureFlag, getFeatureFlags } from '@/services/features';

export const features = createQueryKeys('features', {
  detail: (name: string) => ({
    queryKey: [name],
    queryFn: () => getFeatureFlag(name),
  }),
  list: {
    queryKey: null,
    queryFn: () => getFeatureFlags(),
  },
});
