import { getToken } from '@/utils/cache';

import { UserInfo } from './modules/Account/types';

export default (initialState: UserInfo): Record<string, any> => {
  // 在这里按照初始化数据定义项目中的权限，统一管理
  // 参考文档 https://umijs.org/docs/max/access
  const canSeeAdmin = !!(
    initialState && initialState.name !== 'dontHaveAccess'
  );

  /**注：用户登录后umi的access不会主动更新 */
  /**1.登录调用location.href跳转 能解决access问题(会重新执行初始化) */
  /**2.登录调用 refresh 刷新menu（菜单），但需要access前置全量的(否则403无权限) ，history.push()才能成功*/
  // https://github.com/ant-design/ant-design-pro/pull/6997
  // https://github.com/ant-design/ant-design-pro/issues/6976
  // 因展示全量菜单有安全问题，故采用 window.location.href 跳转暂时解决

  /**默认全量的 */
  // let permissionAll = permissionAllCode.reduce((pre: any, item: string) => {
  //   pre[item] = true;
  //   return pre;
  // }, {});
  let permissionAll = {};

  /**当前用户的 */
  let permissionCurrentUser = {};

  // 已登录
  if (getToken()) {
    // 全量的清空 (本地 联调开发 可注释掉,获取到全量的权限)
    permissionAll = {};

    if (
      initialState?.permissionCodeList &&
      initialState?.permissionCodeList.length
    ) {
      permissionCurrentUser = initialState?.permissionCodeList.reduce(
        (pre: any, item: string) => {
          pre[item] = true;
          return pre;
        },
        {},
      );
    }
  }
  return {
    canSeeAdmin,
    ...permissionAll,
    ...permissionCurrentUser,
    ...initialState?.access,
  };
};
