import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  getBpoById,
  getBpoExpenseFilterDropdown,
  getBpoExpenses,
  getBpoOwnerOptions,
  getBpos,
} from '@/services/bpo';

export const bpo = createQueryKeys('bpo', {
  list: (params) => ({
    queryKey: [params],
    queryFn: () => getBpos(params),
  }),
  detail: (id: number | string) => ({
    queryKey: [String(id)],
    queryFn: () => getBpoById(id),
  }),
  ownerList: {
    queryKey: null,
    queryFn: getBpoOwnerOptions,
  },
  expenseList: (id, params) => ({
    queryKey: [id, params],
    queryFn: () => getBpoExpenses(id, params),
  }),
  expenseDropdowns: (
    id: number | string,
    type: 'wxb-code' | 'contract-name',
  ) => ({
    queryKey: [id, type],
    queryFn: () =>
      getBpoExpenseFilterDropdown(id, type).then((items) =>
        items.map((item) => ({
          label: item,
          value: item,
        })),
      ),
  }),
});
