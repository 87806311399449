import { PropsWithChildren } from 'react';

import { FeatureFlagDevtools } from '@/components/feature-flag-devtools';
import RightContent from '@/components/RightContent';

import BaseTabs from './BaseTabs';

export const BaseLayout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <div className="sticky top-0 z-50 bg-white">
        <div className="flex h-[50px] items-center border-b px-4">
          <div className="flex-1 overflow-hidden">
            <BaseTabs />
          </div>
          <RightContent />
        </div>
      </div>
      <div
        className="flex-1 px-4 py-4 [&>.ka-wrapper]:h-full [&_.ka-content]:h-[calc(100vh-50px-16px-16px)]"
        style={{ overflow: 'auto' }}
      >
        {children}
      </div>
      <FeatureFlagDevtools />
    </>
  );
};
