import { ReadOutlined } from '@ant-design/icons';
import { useLocation } from '@umijs/max';
import { Button, Tour } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { TOUR_MAPPING } from './constants';

const SHOW_TOUR_KEY = 'tour@DaVinci';
const HIDE_TOUR = 'off';
const getPageKey = (page: string) => `tour/${page}@DaVinci`;
const hideAllTour = localStorage.getItem(SHOW_TOUR_KEY) === HIDE_TOUR;

export const GuideTour = () => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);

  const tour = useMemo(() => {
    const keys = Object.keys(TOUR_MAPPING);
    const tourPathKey = keys.find((key) => location.pathname.startsWith(key));
    return tourPathKey ? TOUR_MAPPING[tourPathKey] : null;
  }, [location.pathname]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(
      !hideAllTour &&
        !!tour &&
        localStorage.getItem(getPageKey(tour.key)) !== HIDE_TOUR,
    );
  }, [tour]);

  const closeTour = () => {
    if (!tour) return;
    localStorage.setItem(getPageKey(tour.key), HIDE_TOUR);
    setOpen(false);
    setCurrentPage(0);
  };

  const openTour = () => {
    setCurrentPage(0);
    setOpen(true);
  };

  if (!tour) {
    return null;
  }
  return (
    <>
      <Button
        data-tour-id="show-tour"
        type="text"
        size="small"
        onClick={openTour}
        icon={<ReadOutlined />}
      ></Button>
      <Tour
        open={open}
        current={currentPage}
        onChange={(prev) => setCurrentPage(prev)}
        onClose={closeTour}
        steps={tour.steps}
        disabledInteraction={true}
        gap={{
          offset: 16,
          radius: 4,
        }}
      />
    </>
  );
};
