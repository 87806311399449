/* istanbul ignore file */

import { getIntl } from '@umijs/max';

/**这是个方法 不是hook */
export const getLocale = (
  id: string = 'unknown',
  params?: Record<string, any>,
) => {
  const { formatMessage } = getIntl();

  return formatMessage({ id }, params);
};
