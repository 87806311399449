export default {
  common: {
    noFile: 'No file available',
    fileUploadSuccess: 'File upload successfully',
    fileUploadFailed: 'Failed to upload, please retry',
    deleteSuccess: 'Successfully removed',
    fileDownloadSuccess: 'File downloaded successfully!',
    submitSuccess: 'Successfully submitted',
    apiTimeout: 'Request timeout, please retry!',
    networkError: 'Bad network, please retry!',
    logout: 'Logout',
    messages: {
      captchaRequired: 'Please get verification code first!',
      refetchCaptcha: 'Refetch after {seconds}s',
    },
    updateSuccess: 'Update successful!',
    updateFailed: 'Update failed!',
  },
  tab: {
    refresh: 'Reload Tab',
    closeOthers: 'Close Others',
  },
  internal: {
    home: {
      title: 'Homepage',
      whatsnew: 'My messages - {messageCount} new messages to handle',
    },
  },
};
