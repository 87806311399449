/* istanbul ignore file */

import { COMMON_PATHS } from '@/constants/routes';
import { clearToken } from '@/utils/cache';

import { MFA_REQUIRED_KEY } from './constants';

/**退出 */
export const handleLogOut = (flag = false) => {
  // const { pathname } = history.location;
  clearToken();
  if (flag) {
    location.href = COMMON_PATHS.Login;
    return;
  }
  location.href = COMMON_PATHS.Login;
  // history.push(COMMON_PATHS.Login, { redirect: pathname });
};

export const isMfaRequired = () => {
  const stored = localStorage.getItem(MFA_REQUIRED_KEY);
  if (stored === 'true' || stored === null) return true;
  return false;
};

export const storeMfaRequired = (required: boolean) => {
  localStorage.setItem(MFA_REQUIRED_KEY, `${required}`);
};
