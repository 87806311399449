import * as devtools from './FeatureFlagDevtools';

export const FeatureFlagDevtools: (typeof devtools)['FeatureFlagDevtools'] =
  // process.env.NODE_ENV !== 'development'
  false
    ? () => null
    : devtools.FeatureFlagDevtools;


export const withFeatureFlagDevtools = (Component: React.ComponentType) => () =>
  (
    <>
      <Component />
      <FeatureFlagDevtools />
    </>
  );

