import './index.less';

import {
  CloseOutlined,
  ColumnWidthOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  useAliveController,
  useLocation,
  useMatch,
  useNavigate,
} from '@umijs/max';
import { Button, Dropdown, Tabs } from 'antd';
import { useCallback } from 'react';

import { CacheNode } from '@/components/keep-alive';
import { getLocale } from '@/hooks/useLocale';
import { cn } from '@/utils/cn';

type TabPaneProps = {
  node: CacheNode;
  closeable: boolean;
  onRefresh: () => void;
  onCloseOthers: () => void;
  onTabClick: () => void;
  onClose: () => void;
};

const TabPane = ({
  node,
  onRefresh,
  onCloseOthers,
  onTabClick,
  onClose,
  closeable,
}: TabPaneProps) => {
  const { tabName, matchPattern } = node;
  const match = useMatch(matchPattern);

  return (
    <div
      className={cn('mt-auto', {
        'border-b-2 border-primary': !!match,
      })}
    >
      <Dropdown
        trigger={['contextMenu']}
        menu={{
          items: [
            {
              label: getLocale('tab.refresh'),
              icon: <ReloadOutlined />,
              key: 'refresh',
              onClick: onRefresh,
            },
            {
              label: getLocale('tab.closeOthers'),
              icon: <ColumnWidthOutlined />,
              key: 'other',
              disabled: !closeable,
              onClick: onCloseOthers,
            },
          ],
        }}
      >
        <div className="flex items-center">
          <button
            type="button"
            onClick={onTabClick}
            className={cn('pr-3', {
              'text-primary': !!match,
            })}
          >
            {tabName}
          </button>
          {closeable && (
            <Button
              type="text"
              shape="circle"
              size="small"
              icon={<CloseOutlined style={{ fontSize: '10px' }} />}
              onClick={onClose}
            />
          )}
        </div>
      </Dropdown>
    </div>
  );
};

export default (): React.ReactElement => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // 获取缓存列表
  const { getCachingNodes, dropScope, clear, refreshScope } =
    useAliveController();
  const cachingNodes = getCachingNodes() as unknown as CacheNode[];

  const handlePush = (path: string) => {
    let obj: any = cachingNodes.find((v) => v.name === path);
    const { pathname, search, state } = obj.location;
    navigate(
      {
        pathname,
        search,
      },
      { state },
    );
  };

  // 关闭tab，销毁缓存
  const closeCurrent = (path: any) => {
    dropScope(path);
    // 关闭当前页面，需跳转到其他页签
    if (path === pathname) {
      const index = cachingNodes.findIndex((item) => item.name === path);
      if (index > 0) {
        handlePush(cachingNodes[index - 1].name as string);
      } else {
        handlePush(cachingNodes[1].name as string);
      }
    }
  };

  // 关闭其他
  const handleCloseOthers = (node: CacheNode) => {
    handlePush(node.name);
    clear();
  };

  // 重新加载
  const handleRefresh = (node: CacheNode) => {
    refreshScope(node.name);
  };

  const handleTabClick = useCallback(({ location }: CacheNode) => {
    const to = {
      pathname: location.pathname,
      search: location.search,
    };
    const options = {
      state: location.state,
    };
    navigate(to, options);
  }, []);

  const closeable = cachingNodes.length > 1;
  const tabItems = cachingNodes.map((item) => ({
    label: (
      <TabPane
        node={item}
        onTabClick={() => handleTabClick(item)}
        onRefresh={() => handleRefresh(item)}
        onCloseOthers={() => handleCloseOthers(item)}
        onClose={() => closeCurrent(item.name)}
        closeable={closeable}
      />
    ),
    key: item.name,
  }));

  return (
    <Tabs
      hideAdd
      size="middle"
      className="base-tabs bg-white [&_.ant-tabs-ink-bar]:!hidden [&_[role='tablist']::before]:!content-none"
      activeKey={''}
      items={tabItems}
    />
  );
};
