import type { TourProps } from 'antd';

import { PORTAL_PATHS } from '@/constants/routes';
import { getClientPath } from '@/utils/path';

import { TOUR_ENDING_STEPS } from './tours/constants';
import { MESSAGES_STEPS } from './tours/messages';
import { NEW_REQUIREMENT_STEPS } from './tours/new-requirement';
import { PROJECT_OVERVIEW_STEPS } from './tours/project-overview';

export const TOUR_MAPPING: Record<
  string,
  { steps: TourProps['steps']; key: string }
> = {
  '/portal/dashboard/projects': {
    steps: [...PROJECT_OVERVIEW_STEPS!, ...TOUR_ENDING_STEPS!],
    key: 'project-overview',
  },
  '/portal/dashboard/messages': {
    steps: MESSAGES_STEPS,
    key: 'my-messages',
  },
  [getClientPath(PORTAL_PATHS.ServiceCatalog)]: {
    steps: NEW_REQUIREMENT_STEPS,
    key: 'new-requirement',
  },
};
