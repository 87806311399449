import { Icon } from '@umijs/max';

const ICON_SIZE = '64';

export const HOMEPAGE_MODULES = [
  {
    icon: (
      <Icon
        width={ICON_SIZE}
        height={ICON_SIZE}
        icon="local:project-overview"
      />
    ),
    title: 'Overview',
    color: '#9168ff',
    description:
      'A comprehensive view of all your projects in one place. This module provides visibility into all your contracts and projects.',
  },
  {
    icon: (
      <Icon width={ICON_SIZE} height={ICON_SIZE} icon="local:service-catalog" />
    ),
    title: 'New requirement',
    color: '#337dff',
    description:
      'Submit new requirements directly to our Business Development team with just a few clicks.',
  },
  {
    icon: <Icon width={ICON_SIZE} height={ICON_SIZE} icon="local:statistics" />,
    title: 'Statistics',
    color: '#2fca95',
    description:
      'Gain insights with our Statistics module. You can now find your detailed cost reports, and we will soon release new features on past lab performance.',
  },
  {
    icon: (
      <Icon width={ICON_SIZE} height={ICON_SIZE} icon="local:data-service" />
    ),
    title: 'Data service',
    color: '#ff9b47',
    description:
      'Easily find all your documents here, such as contracts, lab reports, target sequence and more.',
  },
];
