import { APIPageableMeta } from '@/services/types';

export const BPO_API = '/api/v1/bpo';
export const USER_MAGMT_API = '/api/v1/users';
export const REQUIREMENTS_API = '/api/v1/requirements';
export const BD_REQUIREMENT_API = '/api/v1/agent-requirements';
export const BATCH_API = '/api/v1/batches';
export const COMPANY_API = '/api/v1/companies';
export const EXT_INVITATION_API = '/api/v1/invitations';
export const INT_INVITATION_API = '/api/v1/internal/invitations';
export const CLIENT_API = '/api/v1/clients';
export const DEPARTMENT_API = '/api/v1/departments';
export const MESSAGE_ENDPOINT = '/api/v1/messages';
export const FILE_UPLOAD_ENDPOINT = '/api/v1/files';
export const ACCOUNT_API = '/api/v1/accounts';
export const TARGET_SEQUENCE_API = '/api/v1/requirements/target-sequence';
export const MOLECULE_REPORT_API = '/api/v1/statistics/molecule/report';
export const COST_REPORT_API = '/api/v1/statistics/cost/report';
export const FEATURE_TOGGLE_API = '/api/v1/features';

export const DEFAULT_PAGEABLE_META: APIPageableMeta = {
  sort: {
    empty: false,
    sorted: true,
    unsorted: false,
  },
  last: false,
  totalPages: 0,
  totalElements: 0,
  size: 10,
  number: 0,
  first: 0,
  numberOfElements: 0,
  empty: false,
  pageable: {
    sort: {
      empty: false,
      sorted: true,
      unsorted: false,
    },
    pageNumber: 0,
    pageSize: 10,
    offset: 0,
    paged: true,
    unpaged: false,
  },
};

export const DEFAULT_PAGE_NUMBER = 0;
export const DEFAULT_PAGE_SIZE = 10;

export const paginationTotalFormatter = (
  total: number,
  range: [number, number],
) => `${range[0]}-${range[1]} of ${total} items`;
