import { useQuery } from '@umijs/max';

import { queries } from '@/queries';

import { FeatureFlagTypes } from './types';

export const useFeatureFlag = (name: FeatureFlagTypes) => {
  const { data } = useQuery({
    ...queries.features.detail(name),
    cacheTime: Infinity,
    select: (data) => data?.enabled ?? false,
    initialData: { id: 0, name, enabled: false, description: '' },
  });

  return data;
};
