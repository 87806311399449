import { useModel } from '@umijs/max';

export const useProfile = () => {
  const { initialState } = useModel('@@initialState');

  if (!initialState) {
    throw new Error('useAuth should be used within the context of <ProLayout>');
  }

  return initialState;
};
