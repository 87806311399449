import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  getBatchMoleculeList,
  getBatchMoleculeOverview,
  getWorkBatches,
} from '@/services/batch';

export const batches = createQueryKeys('batches', {
  detail: (batchId: number) => ({
    queryKey: [batchId],
    queryFn: () => getWorkBatches(batchId),
    contextQueries: {
      moleculesOverview: {
        queryKey: null,
        queryFn: () => getBatchMoleculeOverview(batchId),
      },
      moleculesList: {
        queryKey: null,
        queryFn: () => getBatchMoleculeList(batchId),
      },
    },
  }),
});
