import { TourProps } from 'antd';

import { getTargetElement } from '../utils';
import { ModuleIntro } from './ModuleIntro';
import { SiteIntroEnding } from './SiteIntroEnding';
import { WelcomeIntro } from './WelcomeIntro';

export const PROJECT_OVERVIEW_STEPS: TourProps['steps'] = [
  {
    title: 'Guide',
    description: <WelcomeIntro />,
  },
  {
    title: null,
    description: <ModuleIntro index={0} />,
    target: getTargetElement('menu-project-overview'),
    placement: 'rightTop',
  },
  {
    title: null,
    description: <ModuleIntro index={1} />,
    target: getTargetElement('menu-new-requirement'),
    placement: 'rightTop',
  },
  {
    title: null,
    description: <ModuleIntro index={2} />,
    target: getTargetElement('menu-statistics'),
    placement: 'rightTop',
  },
  {
    title: null,
    description: <ModuleIntro index={3} />,
    target: getTargetElement('menu-data-service'),
    placement: 'rightTop',
  },
  {
    title: 'Get started',
    description: <SiteIntroEnding />,
  },
  {
    title: 'My Projects',
    description: 'Start from managing your projects here.',
    target: getTargetElement('tab-my-projects'),
    placement: 'rightTop',
  },
  {
    title: 'My Messages',
    description: 'Check your messages here.',
    target: getTargetElement('tab-my-messages'),
    placement: 'rightTop',
  },
  {
    title: 'Project cards',
    description: 'Here are different stages of your projects',
    target: getTargetElement('project-status-list'),
    placement: 'right',
  },
  // {
  //   title: 'Proposal stage',
  //   description:
  //     'Easily track and manage all projects currently under negotiation in the proposal stage, where you can review submitted requirements and monitor progress toward finalizing contracts.',
  //   target: getTargetElement('project-status-PROPOSAL'),
  //   placement: 'right',
  // },
  // {
  //   title: 'In progress stage',
  //   description:
  //     'Stay on top of your active projects by accessing quick updates and progress reports for all ongoing work here.',
  //   target: getTargetElement('project-status-IN_PROGRESS'),
  //   placement: 'right',
  // },
  // {
  //   title: 'Successfully delivered',
  //   description:
  //     'One place where you can access all your completed projects and corresponding deliverables.',
  //   target: getTargetElement('project-status-SUCCESSFULLY_DELIVERED'),
  //   placement: 'right',
  // },
  // {
  //   title: 'Can be resumed',
  //   description:
  //     'Projects here were paused due to some reasons, and you can resume them from where they were stopped.',
  //   target: getTargetElement('project-status-CAN_BE_RESUMED'),
  //   placement: 'right',
  // },
  {
    title: 'Project list',
    placement: 'top',
    description:
      'Your projects are listed here and you can take actions on them or visit the Project 360 page for more details.',
    target: getTargetElement('project-list'),
  },
];
