import { getLocale, setLocale } from '@umijs/max';
import { Space } from 'antd';
import React, { useEffect } from 'react';

import { DEFAULT_LOCALE } from '@/constants/locale';

import Avatar from './Avatar';
import style from './index.less';
import UserInfo from './UserInfo';

const GlobalHeaderRight: React.FC = () => {
  useEffect(() => {
    const language = getLocale();
    if (language !== DEFAULT_LOCALE) {
      setLocale(DEFAULT_LOCALE, false);
    }
  }, []);
  return (
    <div className={style.rightContent}>
      <Space size={20}>
        <UserInfo />
        <Avatar />
      </Space>
    </div>
  );
};
export default GlobalHeaderRight;
