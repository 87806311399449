export const CLIENT_PREFIX = 'portal';
export const INTERNAL_PREFIX = 'xnkvihcmtv';
export const BACK_URL_PARAM = 'backUrl';

export const PORTAL_PATHS = {
  Dashboard: '/dashboard',
  ProjectOverview: '/ProjectOverview',
  Project360: '/Project360/:batchId',
  ServiceCatalog: '/ServiceCatalog', // 父菜单，无页面
  ProteinProduction: '/ServiceCatalog/ProteinProduction',
  ManageExportInfo: '/ServiceCatalog/ManageExportInfo',
  Assay: '/ServiceCatalog/AssayOrder',
  Collaboration: '/Collaboration',
  DataService: '/DataService',
  TargetSequence: '/target-sequence/:id',
  // Observation user
  ProjectOverviewObservation: '/ProjectOverviewObservation',
  DataServiceObservation: '/DataServiceObservation',
} as const;

const PROJECT_MGMT_PREFIX = '/manage';
const EXPENSE_MGMT_PREFIX = '/expense';

export const INTERNAL_PATHS = {
  Home: '/home',
  MyClients: '/MyClients',
  ProjectMgmt: PROJECT_MGMT_PREFIX,
  MyProposals: PROJECT_MGMT_PREFIX + '/proposals',
  ProposalPreview: PROJECT_MGMT_PREFIX + '/proposals/:id',
  MyContracts: PROJECT_MGMT_PREFIX + '/contracts',
  NewRequirement: PROJECT_MGMT_PREFIX + '/NewRequirement', // BD create requirement for client
  MyProjects: PROJECT_MGMT_PREFIX + '/projects',
  ProjectMemo: PROJECT_MGMT_PREFIX + '/projects/:id/memo',
  MyCosProposal: PROJECT_MGMT_PREFIX + '/cos/:id/proposal',
  MyCos: PROJECT_MGMT_PREFIX + '/cos',
  ConfirmProjectProgress: PROJECT_MGMT_PREFIX + '/projects/confirm-progress',
  ExpenseMgmt: EXPENSE_MGMT_PREFIX,
  Bpos: EXPENSE_MGMT_PREFIX + '/bpos',
  BpoDetail: EXPENSE_MGMT_PREFIX + '/bpos/:id',
  ExpenseCostRadar: EXPENSE_MGMT_PREFIX + '/cost-radar',
  ManageDocuments:
    PROJECT_MGMT_PREFIX +
    '/projects/:batchId/documents/:commonFileId/force-upload',
  AccessMgmt: '/AccessManagement',
  UserMgmt: '/UserManagement',
  SiteAdmin: '/SiteAdmin',
  Shipments: '/Shipments/:id', //PM 的
  MyShipments: '/MyShipments', //SM 的
  ManageShipments: '/ManageMyShipments/:id', //SM 的
} as const;

export const COMMON_PATHS = {
  MyAccount: '/account/settings',
  Statistics: '/statistics', // 父菜单，无页面
  LabStatistics: '/statistics/LabStatistics',
  CostRadar: '/statistics/CostRadar',
  CostUpload: '/statistics/CostUpload',
  Login: '/login',
  ResetPassword: '/password-reset',
  Register: '/register',
  Message: '/messages/:id',
  MessageList: '/messages',
  ContractAccess: '/contracts/:groupCode/access',
  AadLoginCallback: '/oauth/aad/callback',
  AadLoginUrl: '/api/v1/oauth/aad',
  Redirecting: '/redirect',
  NotFound: '/not-found',
  SiteMaintenance: '/site-maintenance',
  CostRadarObservation: '/CostRadarObservation',
} as const;
