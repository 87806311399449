import { MenuDataItem, ProSettings } from '@ant-design/pro-components';
import { history, RunTimeLayoutConfig } from '@umijs/max';
import { message, notification } from 'antd';
import { ReactNode } from 'react';
import jsxDevRuntime from 'react/jsx-dev-runtime';
import jsxRuntime from 'react/jsx-runtime';
import { autoFixContext } from 'react-activation';

import favicon from '@/assets/svg/favicon.svg';
import { ACCOUNT_FREEZE_FLAG, DEFAULT_NAME } from '@/constants';
import { DEFAULT_LAYOUT_SETTINGS } from '@/constants/layout-settings';
import {
  BACK_URL_PARAM,
  COMMON_PATHS,
  INTERNAL_PREFIX,
} from '@/constants/routes';
import MenuFooter from '@/layouts/MenuFooter';
import NoPermissionPage from '@/pages/403';
import { getUserInfo } from '@/services/login';
import { getToken } from '@/utils/cache';
// 运行时配置
import { getInternalPath } from '@/utils/path';

import { MENU_ICON_MAPPING } from './constants/menu-icon';
import { BaseLayout } from './layouts/BaseLayout';
import { UserInfo } from './modules/Account/types';
import { initSentry, initSentryUser } from './utils/sentry';

// 设置全局的配置
message.config({
  top: 100,
});

notification.config({
  top: 56,
  duration: 3,
});

initSentry();

// 使用了useModel的页面，再使用KeepAlive，报错
// src/app.ts中添加如下配置：
autoFixContext(
  [jsxRuntime, 'jsx', 'jsxs', 'jsxDEV'],
  [jsxDevRuntime, 'jsx', 'jsxs', 'jsxDEV'],
);

// 无需登录的页面路由
const SKIP_TOKEN_PATHS: string[] = [
  COMMON_PATHS.Login,
  COMMON_PATHS.ResetPassword,
  COMMON_PATHS.Register,
  getInternalPath(COMMON_PATHS.Register),
  getInternalPath(COMMON_PATHS.Login),
  COMMON_PATHS.AadLoginCallback,
  COMMON_PATHS.Redirecting,
  COMMON_PATHS.SiteMaintenance,
];

const shouldSkipToken = (pathname: string) => {
  return SKIP_TOKEN_PATHS.includes(pathname);
};

// 全局初始化数据配置，用于 Layout 用户信息和权限初始化
// 更多信息见文档：https://umijs.org/docs/api/runtime-config#getinitialstate
export async function getInitialState(): Promise<
  UserInfo & {
    name?: string;
    avatar?: string;
    access?: Record<string, any>;
    settings: ProSettings & Record<string, any>;
  }
> {
  if (shouldSkipToken(window.location.pathname)) {
    return { ...DEFAULT_LAYOUT_SETTINGS };
  }

  if (getToken()) {
    try {
      const { data = {} } = await getUserInfo();
      initSentryUser(data.email || 'Unknown user');
      if (data.available === false) {
        // 用户账户状态是被锁定时，向其显示站点维护页面
        window.location.href =
          COMMON_PATHS.SiteMaintenance + ACCOUNT_FREEZE_FLAG;
      }
      return {
        ...DEFAULT_LAYOUT_SETTINGS,
        ...data,
      };
    } catch (e) {
      return { ...DEFAULT_LAYOUT_SETTINGS };
    }
  }
  return { ...DEFAULT_LAYOUT_SETTINGS };
}

export const layout: RunTimeLayoutConfig = ({
  initialState,
  setInitialState,
}) => {
  return {
    disableMobile: true,
    childrenRender: (dom: ReactNode) => <BaseLayout>{dom}</BaseLayout>,
    menu: {
      locale: false,
      params: {
        permissionCodeList: initialState?.permissionCodeList,
      },
    },
    breadcrumbRender: false,
    logo: false,
    menuHeaderRender: (logo, title, props) => {
      if (props?.collapsed) {
        return (
          <div className="flex flex-col items-center justify-center gap-4">
            <img className="my-[10px] h-[26px] w-[26px]" src={favicon} />
          </div>
        );
      }

      return (
        <div className="flex items-center justify-end px-2">
          <img className="my-[10px] mr-[8px] h-[26px] w-[26px]" src={favicon} />
          <span
            className="word-break w-[100%] overflow-hidden text-ellipsis whitespace-nowrap text-lg font-bold"
            style={{ color: '#37BEF0' }}
          >
            {DEFAULT_NAME}
          </span>
        </div>
      );
    },
    onMenuHeaderClick: () => {},
    collapsedButtonRender: false,
    menuFooterRender: (props: any) => (
      <MenuFooter {...props} setInitialState={setInitialState} />
    ),
    // 需要重置掉navigation的title，不能删
    title: '',
    postMenuData: (menusData?: MenuDataItem[]) => {
      return (
        menusData?.map((menuItem) => {
          if (
            menuItem.icon &&
            typeof menuItem.icon === 'string' &&
            menuItem.icon.startsWith('custom:')
          ) {
            const key = menuItem.icon.replace('custom:', '');
            return {
              ...menuItem,
              icon: MENU_ICON_MAPPING[key],
            };
          }
          return menuItem;
        }) ?? []
      );
    },
    siderWidth: 240,
    className: initialState?.settings.collapsed ? 'customSiderBar' : '',
    contentStyle: {
      backgroundColor: '#ECECED',
      padding: 0,
      minHeight: '100vh',
    },
    token: {
      sider: {
        colorMenuBackground: 'rgb(40, 44, 52)',
        colorTextMenuItemHover: '#fff',
        colorTextMenuSelected: '#fff',
        colorTextMenu: 'rgba(255, 255, 255, 0.6)',
        paddingInlineLayoutMenu: 0,
      },
    },
    rightRender: () => <></>,
    disableContentMargin: false,
    iconfontUrl: '//at.alicdn.com/t/c/font_4549717_eypeuja31o.js',
    unAccessible: (
      <BaseLayout>
        <NoPermissionPage />
      </BaseLayout>
    ),
    ...initialState?.settings,
  };
};

export function onRouteChange({ location }: any) {
  if (shouldSkipToken(location.pathname)) {
    return;
  }
  const token = getToken();
  if (!token) {
    const path = window.location.pathname;
    const search = window.location.search;
    const params =
      path !== '/'
        ? `?${BACK_URL_PARAM}=${encodeURIComponent(path + search)}`
        : '';

    if (path.startsWith(`/${INTERNAL_PREFIX}`)) {
      history.push(COMMON_PATHS.Redirecting);
      window.location.href = `${COMMON_PATHS.AadLoginUrl}${params}`;
    } else {
      history.push(COMMON_PATHS.Login + params);
    }
  }
}

export const reactQuery = {
  devtool: {
    initialIsOpen: false,
    position: 'bottom-right',
    panelPosition: 'right',
  },
  queryClient: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
};
