import { Icon } from '@umijs/max';
import { Divider } from 'antd';

export const WelcomeIntro = () => {
  return (
    <div className="mt-4 w-[440px] px-6 py-4">
      <div className="mb-8 grid grid-cols-2 gap-8">
        <div className="flex flex-col items-center gap-4 text-[#337dff]">
          <Icon icon="local:tube" width="48" height="48" />
          <p className="mb-0 text-center font-semibold leading-4">
            Research
            <br /> Protein Production
          </p>
        </div>
        <div className="flex flex-col items-center gap-4 text-[#337dff]">
          <Icon icon="local:questionnaire" width="48" height="48" />
          <p className="mb-0 text-center font-semibold leading-[32px]">Assay</p>
        </div>
      </div>
      <h3 className="text-center">New Requirement</h3>
      <p className="text-center">
        Designed to simplify the process of submitting project requests. With
        just one step, you can now easily create and submit new requirements
        directly to your BD.
      </p>
    </div>
  );
};
