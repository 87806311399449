import { COMMON_PATHS, INTERNAL_PATHS, PORTAL_PATHS } from '@/constants/routes';
import { UserInfo } from '@/modules/Account/types';
import { AccountRoleName, INTERNAL_ROLES } from '@/modules/UserSelect/types';
import { getClientPath, getInternalPath } from '@/utils/path';

export const getRoutes = (arr: any[], newArr: any[] = []) => {
  arr.forEach((v) => {
    if (v.routes && v.routes.length) {
      getRoutes(v.routes, newArr);
    }
    if (v.path) {
      newArr.push(v);
    }
  });
  return newArr;
};

export const hasRole = (
  roleList: UserInfo['roleList'] = [],
  role: AccountRoleName,
): boolean => {
  const roles = roleList.map((item) => item.name);
  return roles.includes(role);
};

const INTERNAL_HOMEPAGE = getInternalPath(INTERNAL_PATHS.Home);
const ROLE_HOMEPAGE_MAPPING: Record<AccountRoleName, string> = {
  [AccountRoleName.Customer]: getClientPath(PORTAL_PATHS.Dashboard),
  [AccountRoleName.Observation]: getClientPath(
    PORTAL_PATHS.ProjectOverviewObservation,
  ),
  [AccountRoleName.BD]: INTERNAL_HOMEPAGE,
  [AccountRoleName.PM]: INTERNAL_HOMEPAGE,
  [AccountRoleName.CS]: INTERNAL_HOMEPAGE,
  [AccountRoleName.Fin]: INTERNAL_HOMEPAGE,
  [AccountRoleName.ML]: INTERNAL_HOMEPAGE,
  [AccountRoleName.AL]: INTERNAL_HOMEPAGE,
  [AccountRoleName.FL]: INTERNAL_HOMEPAGE,
  [AccountRoleName.SM]: INTERNAL_HOMEPAGE,
  [AccountRoleName.Admin]: getInternalPath(INTERNAL_PATHS.SiteAdmin),
  [AccountRoleName.SR]: getInternalPath('/target-sequence'),
};

export const getHomepageUrl = (roleList: UserInfo['roleList'] = []): string => {
  if (roleList.length < 1) return getClientPath(COMMON_PATHS.NotFound);
  return (
    ROLE_HOMEPAGE_MAPPING[roleList[0].name] ??
    getClientPath(COMMON_PATHS.NotFound)
  );
};

export const isInternalRole = (roleList: UserInfo['roleList'] = []) => {
  if (roleList.length < 1) return false;
  const role = roleList[0].name;
  return INTERNAL_ROLES.indexOf(role) > -1;
};
