import { HOMEPAGE_MODULES } from './constants';

export const SiteIntroEnding = () => {
  const module = HOMEPAGE_MODULES[0];
  return (
    <div className="flex h-[240px] w-[440px] flex-col items-center justify-center gap-6 px-4 py-6">
      <div style={{ color: module.color }}>{module.icon}</div>
      <h3 className="text-center">
        You&apos;re all set, let&apos;s get started from <br />
        <strong>Overview</strong>.
      </h3>
    </div>
  );
};
