import { FC } from 'react';

import { HOMEPAGE_MODULES } from './constants';

type Props = {
  index: number;
};

export const ModuleIntro: FC<Props> = ({ index }) => {
  const module = HOMEPAGE_MODULES[index];
  if (!module) {
    return null;
  }
  return (
    <div className="flex w-[440px] gap-8 px-4 pt-6">
      <div className="pt-2" style={{ color: module.color }}>
        {module.icon}
      </div>
      <div>
        <h2>{module.title}</h2>
        <p>{module.description}</p>
      </div>
    </div>
  );
};
