import { HOMEPAGE_MODULES } from './constants';

export const WelcomeIntro = () => {
  return (
    <div className="mt-4 w-[440px] px-6 py-4">
      <div className="mb-6 grid grid-cols-2 gap-8">
        {HOMEPAGE_MODULES.map((item) => (
          <div key={item.title} className="flex flex-col items-center gap-2">
            <div style={{ color: item.color }}>{item.icon}</div>
            <p className="whitespace-nowrap text-center font-semibold">
              {item.title}
            </p>
          </div>
        ))}
      </div>
      <h3 className="text-center">Welcome to DaVinci!</h3>
      <p className="text-center">
        Start placing orders, managing your projects
        <br /> and data assets here.
      </p>
    </div>
  );
};
