import {
  ProjectListItem,
  ProjectProgressDetailProps,
} from '@/pages/BackPage/ConfirmProjectProgress/types';
import request from '@/utils/request';

const BASE_URL = '/api/v1/batches/molecules';

export const getProjectProgressList = (): Promise<ProjectListItem[]> => {
  return request.get(`${BASE_URL}/projects/list`).then(({ data }) => data);
};

export const getProjectProgressDetail = (
  batchDetailId: number,
): Promise<ProjectProgressDetailProps[]> => {
  return request
    .get(`${BASE_URL}/activity/${batchDetailId}`)
    .then(({ data }) => data);
};

export const acknowledgeProjectProgress = (
  batchDetailId: number,
): Promise<void> => {
  return request.put(`${BASE_URL}/acknowledge/${batchDetailId}`);
};
