import request from '@/utils/request';

export type FeatureFlag = {
  id: number;
  name: string;
  description: string;
  enabled: boolean;
};

type ToggleFeatureFlagParams = {
  name: string;
  enabled: boolean;
};

export const getFeatureFlags = (): Promise<FeatureFlag[]> => {
  return request.get('/api/v1/features').then((res) => res.data);
};

export const getFeatureFlag = (name: string): Promise<FeatureFlag | null> => {
  return request.get(`/api/v1/features/${name}`).then((res) => res.data);
};

export const toggleFeatureFlag = ({
  name,
  enabled,
}: ToggleFeatureFlagParams): Promise<boolean> => {
  return request
    .put(`/api/v1/features/${name}`, { enabled })
    .then((res) => res.data);
};
