import { decrypt, encrypt } from 'crypto-js/aes';
import UTF8, { parse } from 'crypto-js/enc-utf8';
import ECB from 'crypto-js/mode-ecb';
import pkcs7 from 'crypto-js/pad-pkcs7';

export interface EncryptionParams {
  key: string;
  iv: string;
}

export class AesEncryption {
  private key;
  private iv;

  constructor(opt: Partial<EncryptionParams> = {}) {
    const { key, iv } = opt;
    if (key) {
      this.key = parse(key);
    }
    if (iv) {
      this.iv = parse(iv);
    }
  }

  get getOptions() {
    return {
      mode: ECB,
      padding: pkcs7,
      iv: this.iv,
    };
  }

  encryptByAES(cipherText: string) {
    if (this.key) {
      return encrypt(cipherText, this.key, this.getOptions).toString();
    } else {
      throw 'Encryption key not initiated';
    }
  }

  decryptByAES(cipherText: string) {
    if (this.key) {
      return decrypt(cipherText, this.key, this.getOptions).toString(UTF8);
    } else {
      throw 'Encryption key not initiated';
    }
  }
}

// export function encryptByBase64(cipherText: string) {
//   return UTF8.parse(cipherText).toString(Base64);
// }

// export function decodeByBase64(cipherText: string) {
//   return Base64.parse(cipherText).toString(UTF8);
// }

// export function encryptByMd5(password: string) {
//   return md5(password).toString();
// }
