import { getLocalCaches } from './cache';
import request from './request';

// FIXME(qiao.guang)
// eslint-disable-next-line no-var
var promise: any;
export async function handleRefreshToken() {
  if (promise) {
    return promise;
  }

  // FIXME(qiao.guang)
  // eslint-disable-next-line no-async-promise-executor
  promise = new Promise(async (resolve) => {
    const resp = await request.post(
      '/api/v1/auth/token/refresh',
      {
        refreshToken: getLocalCaches('refreshToken'),
      },
      {
        isRefreshToken: true,
      } as any,
    );

    resolve(resp);
  });

  promise.finally(() => {
    promise = null;
  });

  return promise;
}

export const isRefreshRequest = (config: any) => {
  return !!config.isRefreshToken;
};
