import clsx, { type ClassValue as Argument } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: Argument[]) {
  return twMerge(clsx(inputs));
}

const tailwindColors = [
  '#67e8f9',
  '#22d3ee',
  '#06b6d4',
  '#0891b2',
  '#a5b4fc',
  '#818cf8',
  '#6366f1',
  '#4f46e5',
  '#d4d4d4',
  '#a3a3a3',
  '#737373',
  '#525252',
  '#fdba74',
  '#fb923c',
  '#f97316',
  '#ea580c',
  '#fde047',
  '#facc15',
  '#eab308',
  '#ca8a04',
  '#fca5a5',
  '#f87171',
  '#ef4444',
  '#dc2626',
  '#bef264',
  '#a3e635',
  '#84cc16',
  '#65a30d',
  '#5eead4',
  '#2dd4bf',
  '#14b8a6',
  '#0d9488',
];

export function getRandomRGBColor() {
  return tailwindColors[Math.floor(Math.random() * tailwindColors.length)];
}
