import { BPO_API } from '@/constants/api';
import {
  BpoDetail,
  BpoExpenseListItem,
  BpoExpenseQueryParam,
  BpoListItem,
  BpoOwnerOption,
  CreateBpoInfoReq,
} from '@/pages/BackPage/BpoManagement/types';
import request from '@/utils/request';

import { APIPageableData, IAPIPageableReq } from './types';

export const createBpo = (data: CreateBpoInfoReq): Promise<void> => {
  return request.post(BPO_API, data, { customErrorMessage: true });
};

export const updateBpo = (
  id: number | string,
  data: CreateBpoInfoReq,
): Promise<void> => {
  return request.put(`${BPO_API}/${id}`, data, { customErrorMessage: true });
};

export const deleteBpo = (id: number | string): Promise<void> =>
  request.delete(`${BPO_API}/${id}`);

export const getBpos = (
  params: IAPIPageableReq,
): Promise<APIPageableData<BpoListItem>> => {
  return request.get(BPO_API, { params }).then(({ data }) => data);
};

export const getBpoOwnerOptions = (): Promise<BpoOwnerOption[]> => {
  return request.get(`${BPO_API}/owners`).then(({ data }) => data);
};

export const getBpoById = (id: number | string): Promise<BpoDetail> => {
  return request.get(`${BPO_API}/${id}`).then(({ data }) => data);
};

export const getBpoExpenses = (
  bpoId: number | string,
  params: BpoExpenseQueryParam,
): Promise<APIPageableData<BpoExpenseListItem>> => {
  return request
    .get(`${BPO_API}/${bpoId}/expense-records`, { params })
    .then(({ data }) => data);
};

export const addBpoExpenses = (
  bpoId: number | string,
  expenseRecordIds: number[],
): Promise<void> =>
  request.put(`${BPO_API}/${bpoId}/expense-records`, {
    expenseRecordIds,
  });

export const removeBpoExpense = (expenseId: number): Promise<void> => {
  return request.delete(`${BPO_API}/expense-records/${expenseId}`);
};

export const getBpoExpenseFilterDropdown = (
  bpoId: number | string,
  type: 'wxb-code' | 'contract-name',
): Promise<string[]> => {
  return request
    .get(`${BPO_API}/${bpoId}/expense-records/${type}`)
    .then(({ data }) => data);
};
