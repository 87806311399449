import * as Sentry from '@sentry/react';

const environment = process.env.SENTRY_ENVIRONMENT;

const getEnv = () => {
  let env = environment;
  const hostname = window.location.hostname;
  if (hostname === 'localhost') {
    env = 'local';
  } else if (hostname.includes('-dev')) {
    env = 'dev';
  } else if (hostname.includes('-uat')) {
    env = 'uat';
  } else if (hostname === 'davinci.wuxibiologics.com') {
    env = 'prod';
  }
  return env;
};

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://ecd93b5cce8fa1c0900d2b58158d7aa3@o4507887737044992.ingest.de.sentry.io/4507887800942672',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    ignoreErrors: [/^(.*)findDOMNode(.*)/],
    environment: getEnv(),
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost'].concat(process.env.API_URL ?? []),
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  Sentry.setTag('screenWidth', screenWidth);
};

export const initSentryUser = (email: string) => {
  Sentry.setUser({
    email,
  });
};

export const captureLogin = (email: string) => {
  Sentry.captureEvent({
    message: 'Login',
    type: 'transaction',
    user: {
      email,
    },
  });
};

export const captureDownload = (downloadType: string) => {
  Sentry.captureEvent({
    message: `Download${downloadType}`,
    type: 'transaction',
  });
};
