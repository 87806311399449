import type { AxiosInstance } from 'axios';
import axios from 'axios';

import {
  requestAuthInterceptor,
  responseErrorHandlerInterceptor,
  responseSaveTokenInterceptor,
} from './interceptors';

const request: AxiosInstance = axios.create({
  baseURL: '',
  timeout: 60 * 1000,
});

request.interceptors.request.use(requestAuthInterceptor);

request.interceptors.response.use(
  responseSaveTokenInterceptor,
  responseErrorHandlerInterceptor,
);

export default request;
