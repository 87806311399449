import { useState } from 'react';

const useProject360 = () => {
  const [activity, setActivity] = useState<number | string>('');
  return {
    activity,
    setActivity,
  };
};

export default useProject360;
