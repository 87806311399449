import dayjs from 'dayjs';

import { useProfile } from '@/hooks/useProfile';

import { GuideTour } from '../guide-tour/GuideTour';

const AvatarDropdown: React.FC = () => {
  const initialState = useProfile();

  const userBanner =
    initialState && initialState.firstName
      ? `, ${initialState.firstName} from ${initialState.company?.name || ''}`
      : '';

  return (
    <div className="flex items-center gap-4">
      <GuideTour />
      <div className="userInfo">
        <p className="userBanner">Welcome back{userBanner}</p>
        {initialState?.activity?.loginSuccessTime && (
          <p className="localTime">
            Last time we saw you:{' '}
            {dayjs(initialState.activity.loginSuccessTime).format(
              'YYYY-MM-DD HH:mm:ss',
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default AvatarDropdown;
