export default {
  common: {
    noFile: '暂无可下载文件',
    fileUploadSuccess: '上传成功',
    fileUploadFailed: '上传失败',
    fileDownloadSuccess: '下载成功！',
    deleteSuccess: '删除成功',
    submitSuccess: '提交成功',
    apiTimeout: '接口请求超时，请刷新页面重试！',
    networkError: '网络异常，请检查你的网络连接是否正常',
    logout: '退出系统',
    messages: {
      captchaRequired: '请先获取验证码',
      refetchCaptcha: '{seconds}秒后重新获取',
    },
    updateSuccess: '修改成功',
    updateFailed: '修改失败',
  },
  tab: {
    refresh: '重新加载',
    closeOthers: '关闭其他',
  },
  internal: {
    home: {
      title: '首页',
      whatsnew: '您有 {messageCount} 条新消息！',
    },
  },
};
