// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import {
  defaultContext,
  QueryClient,
  QueryClientProvider
} from '/home/yaoming/temp/v20241108132834-release3/da-vinci-frontend/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1/node_modules/@tanstack/react-query';
import { ReactQueryDevtools } from '/home/yaoming/temp/v20241108132834-release3/da-vinci-frontend/node_modules/.pnpm/@tanstack+react-query-devtools@4.36.1_@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1/node_modules/@tanstack/react-query-devtools';
import { reactQuery as reactQueryConfig } from '@/app';
const client = new QueryClient(reactQueryConfig.queryClient || {});
export function rootContainer(container) {
  return (
    <QueryClientProvider
      client={client}
      context={defaultContext}
    >
      {container}
      <ReactQueryDevtools
  context={defaultContext}
  initialIsOpen={false}
  {...(reactQueryConfig.devtool || {})}
/>
    </QueryClientProvider>
  );
}
