export enum AccountRoleName {
  PM = 'PM', // Internal
  BD = 'BD', // Internal
  CS = 'CS', // Internal
  Fin = 'Fin', // Internal
  ML = 'ML', // Internal
  AL = 'AL', // Internal
  FL = 'FL', // Internal
  Customer = 'Customer', // External, Management Client
  Observation = 'Observation', // External, Observation Client
  Admin = 'Admin', // Admin
  SM = 'SM', // SM
  SR = 'SR', // sequence receiver
}

export const INTERNAL_ROLES = [
  AccountRoleName.PM,
  AccountRoleName.BD,
  AccountRoleName.CS,
  AccountRoleName.FL,
  AccountRoleName.Fin,
  AccountRoleName.ML,
  AccountRoleName.AL,
  AccountRoleName.SM,
  AccountRoleName.Admin,
];

export const INTERNAL_ROLE_NAMES = [
  'Analysis Lead',
  'Business Development',
  'Function Lead',
  'Molecule Function Lead',
  'Project Manager',
  'Sequence Receiver',
  'Shipping Manager',
  'Admin',
  AccountRoleName.CS,
  AccountRoleName.Fin,
];

export interface IAccountRole {
  id: number;
  fullName?: string; // Management, Observation, Business Development
  name: string; // Customer, Observation, BD, PM
  code: AccountRoleName;
  permissionList?: string[];
}

export type AccountRoleGroup = 'Internal' | 'External' | 'Admin';

export interface ICompany {
  id: number;
  name: string;
}

export interface IAccount {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  company: ICompany;
  roleList: IAccountRole[];
  permissionCodeList: string[];
  inviterAccountId?: null | number;
  department?: string;
  title?: string;
  maskPhone?: string;
  zone?: object;
}
