// noinspection ES6PreferShortImport
import { generatePath } from '@umijs/max';

import {
  CLIENT_PREFIX,
  COMMON_PATHS,
  INTERNAL_PATHS,
  INTERNAL_PREFIX,
  PORTAL_PATHS,
} from '../constants/routes';

export const getClientPath = <T extends string>(path: T) =>
  `/${CLIENT_PREFIX}${path}` as const;

export const getInternalPath = <T extends string>(path: T) =>
  `/${INTERNAL_PREFIX}${path}` as const;

export const getProjectMemoPath = (projectCode?: string) =>
  projectCode
    ? generatePath(getInternalPath(INTERNAL_PATHS.ProjectMemo), {
        id: projectCode,
      })
    : '/';

export const getViewProposalPath = (requirementId: number) =>
  generatePath(getInternalPath(INTERNAL_PATHS.ProposalPreview), {
    id: String(requirementId),
  });

export const getViewCoProposalPath = (coId: number) =>
  generatePath(getInternalPath(INTERNAL_PATHS.MyCosProposal), {
    id: String(coId),
  });

export const getContractAccessPath = (groupCode: string | null) =>
  groupCode
    ? generatePath(getClientPath(COMMON_PATHS.ContractAccess), {
        groupCode,
      })
    : COMMON_PATHS.NotFound;

export const getProject360Path = (batchId?: string | number) => {
  if (!batchId) return COMMON_PATHS.NotFound;
  return generatePath(getClientPath(PORTAL_PATHS.Project360), {
    batchId: String(batchId),
  });
};
